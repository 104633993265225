import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";
import {
  AiFillTrademarkCircle
} from "react-icons/ai";
import {
  FaCannabis,
  FaGlobeAmericas,
  FaBinoculars,
  FaHandshake
} from "react-icons/fa";
import {
  IoMdCheckmarkCircleOutline
} from "react-icons/io";
import {
  PiPencilLine,
  PiGavelBold
} from "react-icons/pi";
import {
  RiFileCopy2Fill
} from "react-icons/ri";
import {
  FiChevronUp,
  FiCheck
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";


const SlideList = [
  {
    textPosition: 'text-right',
    bgImage: 'bg_image--35',
    category: '',
    title: 'Trademarks.',
    description: 'I work with businesses, General Counsel, and other attorneys to provide next level trademark services.',
    buttonText: 'Connect',
    buttonLink: 'https://www.linkedin.com/in/alicedenenberg/'
  }
];

const ServiceListOne = [
  {
    icon: <AiFillTrademarkCircle />,
    title: 'Advanced Trademark Strategies'
  },
  {
    icon: <FaGlobeAmericas />,
    title: 'Worldwide Protection + Madrid International Registrations'
  },
  {
    icon: <FaCannabis />,
    title: 'Cannabis Trademarks'
  },
  {
    icon: <IoMdCheckmarkCircleOutline />,
    title: 'Certification Marks'
  },
  {
    icon: <RiFileCopy2Fill />,
    title: 'Trademark Audits'
  },
  {
    icon: <FaHandshake />,
    title: 'General Counsel Partnerships'
  },
  {
    icon: <PiPencilLine />,
    title: 'Office Actions and Strategy'
  },
  {
    icon: <FaBinoculars />,
    title: 'Comprehensive + Knockout Searches'
  },
  {
    icon: <PiGavelBold />,
    title: 'TTAB Actions'
  }
];

const articles = [
  {
    title: 'From Maple Leaves to Eagles How Descriptive Trademarks Differ in Canada and the U.S.',
    href: 'https://www.altlegal.com/blog/from-maple-leaves-to-eagles/'
  },
  {
    title: 'Protecting Your Style - Why Trademarks Matter in Today\'s Brand-Happy World',
    href: 'https://citylifestyle.com/morristown-nj/articles/finance-and-legal-services/protecting-your-style'
  },
  {
    title: 'Protecting Your Brand Portfolio: Four Steps for a Proper Trademark Audit',
    href: 'https://ipwatchdog.com/2018/11/25/brand-portfolio-trademark-audit/id=103371/'
  },
  {
    title: 'Revamping Your Trademark Strategy in China',
    href: 'https://www.law360.com/articles/1159249'
  },
  {
    title: 'Don\'t Lose Your Brand: 10 Reasons Why Trademark Searches Are Essential Before Filing a Trademark Application',
    href: 'https://grr.com/publications/dont-lose-your-brand-10-reasons-why-trademark-searches-are-essential-before-filing-a-trademark-application/'
  },
  {
    title: 'Pardon My French: Get Ready for Stricter Rules on English-Language Trademarks in Quebec',
    href: 'https://grr.com/publications/pardon-my-french-get-ready-for-stricter-rules-on-english-language-trademarks-in-quebec/'
  },
  {
    title: 'The Best Time to File for a Trademark and Other Important Questions',
    href: 'https://grr.com/publications/the-best-time-to-file-for-a-trademark-and-other-important-questions/'
  },
  {
    title: 'The Best Time to Consider a Trademark and Other Important Questions: A Quick Primer for New and Existing Businesses',
    href: 'https://grr.com/publications/the-best-time-to-consider-a-trademark-and-other-important-questions-a-quick-primer-for-new-and-existing-businesses/'
  },
  {
    title: 'To Be Or Not To Be (A Trademark) - A Failure To Function Overview',
    href: 'https://grr.com/publications/to-be-or-not-to-be-a-failure-to-function/'
  },
  {
    title: 'Expedited Changes Coming to the Canadian Trademark Office: A Comparative Overview with the U.S. System',
    href: 'https://grr.com/publications/expedited-changes-coming-to-the-canadian-trademark-office-a-comparative-overview-with-the-u-s-system/'
  },
  {
    title: 'Demystifying Copyright: 5 Essential Questions Answered',
    href: 'https://grr.com/publications/demystifying-copyright-5-essential-questions-answered/'
  },
  {
    title: 'It Can\'t Be Trademarked! An Overview of Goods in Trade and Use for the Benefit of Others',
    href: 'https://grr.com/publications/it-cant-be-trademarked-an-overview-of-goods-in-trade-and-use-for-the-benefit-of-others/'
  },
  {
    title: 'Why Refile When You can Amend? Permissible Amendments to U.S. Trademark Registrations',
    href: 'https://grr.com/publications/why-refile-when-you-can-amend-permissible-amendments-to-u-s-trademark-registrations/'
  },
  {
    title: 'Filing for Trademarks in a Pandemic? Turning Crisis Into Opportunity',
    href: 'https://grr.com/publications/filing-for-trademarks-in-a-pandemic-turning-crisis-into-opportunity/'
  },
  {
    title: 'Update on Cannabis, Marijuana, Hemp and CBD Trademarks',
    href: 'https://grr.com/publications/update-on-cannabis-marijuana-hemp-and-cbd-trademarks/'
  },
  {
    title: 'A Tale of 3 Specimens',
    href: 'https://grr.com/publications/a-tale-of-3-specimens/'
  },
  {
    title: 'Canadian Trademarks: File Now, Renew Now!',
    href: 'https://grr.com/publications/canadian-trademarks-file-now-renew-now/'
  }
];

class Primary extends Component{
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal () {
    this.setState({isOpen: true})
  }
  render(){
    return(
      <Fragment>
        <Helmet pageTitle="Alice Denenberg Attorney Advertising" />

        {/* Start Header Area  */}
        <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value , index) => (
                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ''}
                          {value.title ? <h1 className="title">{value.title}</h1> : ''}
                          {value.description ? <p className="description">{value.description}</p> : ''}
                          {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div id="services" className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map( (val , i) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2">
                    <div className="icon">
                      {val.icon}
                    </div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Counterup Area */}
        <div className="counterup-area pb--80 pt--40 bg_image bg_image--35 theme-text-white" data-black-overlay="7">
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}

        {/* Start About Area  */}
        <div id="about" className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">
                      Alice Denenberg is a skilled trademark attorney with over 15 years of experience. She frequently consults for other trademark attorneys and General Counsel, who seek out her knowledge on advanced trademark issues, both in the U.S. and internationally.
                    </p>
                    <p className="description">
                      Alice's experience includes US and foreign trademark prosecution and securing trademark protection worldwide. She has advised domestic and international clients on a wide range of trademark-related matters, including clearance, risk management, searches, opinions, filing strategy, filing, registration, maintenance, and enforcement.
                    </p>
                    <p className="description">
                      Alice's clients consist of businesses and other attorneys, and spans across industries, including consumer goods (beauty, apparel/fashion, jewelry, housewares, toys, foods/supplements, and pet products), restaurants, cutting edge software and technology, mobile applications, cryptocurrency, and cannabis.
                    </p>
                    <p className="description">
                      To best serve her clients, Alice is continuously expanding her knowledge of trademark law.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail position-relative">
                  <img className="w-100" src="/assets/images/about/about-7.jpg" alt="About Images"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Blog Area */}
        <div id="articles" className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title">
                  <h2 className="title">Articles/Presentations</h2>
                  <p>
                    Select articles written by Alice Denenberg
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--20">
              <ul className="list-style--1">
                  {articles.map((article, index) => {
                      return <li key={index}><FiCheck /> <a href={article.href} target="_blank" rel="noopener noreferrer" style={{
                        textDecoration: 'underline'
                      }}>{article.title}</a></li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    )
  }
}

export default Primary;
